import { useState } from 'react'
import { Dropdown } from '../../../ui'
import toast from 'react-hot-toast'
import {
  MenuContainer,
  MenuContainerHeader,
  CreateCategoryButton,
  MenuContainerItem,
  Checkbox,
  FavIcon,
} from './style'
import { FavoriteProps } from './props'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import {
  useGetAllPrescriberFavoriteListsByPrescriberId,
  useDeletePrescriberFavoriteListItemByProductIdAndListId,
  useAddPrescriberFavoriteListItemToList,
  useGetAllPrescriberFavoriteListsByProductId,
} from '../../../../services/api/favorites/favorites'
import { useGetMe } from '../../../../utils/hooks/getMe'

import { useQueryClient } from 'react-query'
import CreateCategory from '../../favorites/categories/create-category'
import {
  refetchAllFavorite,
  refetchaFvoriteListsByProductId,
} from '../../../../utils/helpers'
import { PageType } from '../../../../utils/constants'
import { ButtonFavorite } from '../style'

const Favorite = ({
  bookmarks,
  product,
  type,
  favoriteId,
  userFavoriteLists,
  refetchCurrentFavoriteList,
}: FavoriteProps) => {
  // Attrubutes
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState<boolean>(false)
  const isProductNotInCurrentFavoriteList = !userFavoriteLists?.find(
    userProduct => userProduct?.handle === product?.handle
  )
  const { data: prescriber } = useGetMe()

  const queryClient = useQueryClient()

  const { mutateAsync: addPrescriberFavoriteListItemToList } =
    useAddPrescriberFavoriteListItemToList({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été ajouté aux favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })
  const { mutateAsync: deletePrescriberFavoriteListItemByProductIdAndListId } =
    useDeletePrescriberFavoriteListItemByProductIdAndListId({
      mutation: {
        onSuccess: () => {
          toast.success('Le produit a bien été supprimé des favoris')
          refetchAllFavorite(queryClient, prescriber?.id)
          refetchaFvoriteListsByProductId(
            queryClient,
            prescriber?.id,
            product?.id
          )
          if (!!refetchCurrentFavoriteList) return refetchCurrentFavoriteList()
        },
        onError: error => {
          toast.error('Oops! Une erreur est survenue.')
        },
      },
    })

  const { data: favoriteLists } =
    useGetAllPrescriberFavoriteListsByPrescriberId(prescriber?.id, null, {
      query: {
        enabled: !!prescriber?.id,
      },
    })

  const { data: favoriteListsByProductId } =
    useGetAllPrescriberFavoriteListsByProductId(prescriber?.id, product?.id, {
      query: {
        enabled: !!prescriber?.id && !!product?.id,
      },
    })

  const favoriteListsIdByProductId = favoriteListsByProductId?.map(i => i.id)

  // Function
  const handleClickOnFavoriteListItem = (favoriteId: string) => {
    if (favoriteListsIdByProductId?.includes(favoriteId))
      deletePrescriberFavoriteListItemByProductIdAndListId({
        productId: product?.id,
        favoriteListId: favoriteId,
      })
    if (!favoriteListsIdByProductId?.includes(favoriteId))
      addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })
  }

  // Functions
  const closeCreateCategoryDialog = () => {
    setIsCreateCategoryDialogOpen(false)
  }

  const handleClickOnFavIcon = () => {
    if (!favoriteLists?.length) {
      setIsCreateCategoryDialogOpen(true)
    }

    if (isProductNotInCurrentFavoriteList)
      return addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })

    return deletePrescriberFavoriteListItemByProductIdAndListId({
      productId: product?.id,
      favoriteListId: favoriteId,
    })
  }

  const addProductToFavorite = (favoriteId: string) => {
    addPrescriberFavoriteListItemToList({
      favoriteListId: favoriteId,
      data: {
        productId: product?.id,
      },
    })
  }

  function handleAddToFavorite() {
    if (!favoriteLists?.length) {
      return setIsCreateCategoryDialogOpen(true)
    }
    if (isProductNotInCurrentFavoriteList)
      return addPrescriberFavoriteListItemToList({
        favoriteListId: favoriteId,
        data: {
          productId: product?.id,
        },
      })

    return deletePrescriberFavoriteListItemByProductIdAndListId({
      productId: product?.id,
      favoriteListId: favoriteId,
    })
  }

  function textToDisplay() {
    if (isProductNotInCurrentFavoriteList) return 'Ajouter à'
    return 'Retirer de'
  }

  const favoriteName = favoriteLists
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .find(favorite => favorite.id === favoriteId)?.name

  // Render
  return (
    <>
      <Dropdown
        content={
          !favoriteId ? (
            !!favoriteLists?.length && (
              <MenuContainer>
                <MenuContainerHeader>
                  <CreateCategoryButton
                    onClick={() => setIsCreateCategoryDialogOpen(true)}
                  >
                    Créer une nouvelle catégorie
                  </CreateCategoryButton>
                </MenuContainerHeader>
                {favoriteLists?.map(favorite => {
                  return (
                    <MenuContainerItem
                      onClick={() =>
                        handleClickOnFavoriteListItem(favorite?.id)
                      }
                    >
                      <div>
                        <Checkbox
                          checked={favoriteListsIdByProductId?.includes(
                            favorite?.id
                          )}
                        />
                        <p> {favorite?.name}</p>
                      </div>
                    </MenuContainerItem>
                  )
                })}
              </MenuContainer>
            )
          ) : (
            <></>
          )
        }
      >
        {type === PageType.FAVORITE ? (
          <ButtonFavorite onClick={handleAddToFavorite}>
            {favoriteId
              ? `${textToDisplay()} ${favoriteName}`
              : 'Ajouter aux favoris'}
          </ButtonFavorite>
        ) : (
          <FavIcon onClick={handleClickOnFavIcon}>
            {bookmarks ? <AiFillStar /> : <AiOutlineStar />}
          </FavIcon>
        )}
      </Dropdown>
      {isCreateCategoryDialogOpen && (
        <CreateCategory
          isOpen={isCreateCategoryDialogOpen}
          onClose={closeCreateCategoryDialog}
          addProductToFavorite={addProductToFavorite}
          refetchCurrentFavoriteList={refetchCurrentFavoriteList}
        />
      )}
    </>
  )
}

export default Favorite
