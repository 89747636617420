import { useEffect, useState } from 'react'
import { useGetAllItemsBySearch } from '../../../../services/api/search/search'
import { SearchDTO, SearchType } from '../../../../services/api/types'
import { ILogicReturn } from './types'
import { SuggestionsProps } from './props'
import useDebounce from '../../../../utils/hooks/useDebounce'

export const useSuggestionsLogic = ({
  search,
  setSearch,
  packagingType,
  values,
  setFieldValue,
}: SuggestionsProps): ILogicReturn => {
  // State
  const [brandArray, setBrandArray] = useState<SearchDTO[]>([])
  const [ingredientArray, setIngredientArray] = useState<SearchDTO[]>([])
  const [productArray, setProductArray] = useState<SearchDTO[]>([])
  const [tagForProductArray, setTagForProductArray] = useState<SearchDTO[]>([])
  const [typeForProductArray, setTypeForProductArray] = useState<SearchDTO[]>(
    []
  )
  const shouldHidePopup =
    !brandArray.length &&
    !ingredientArray.length &&
    !tagForProductArray.length &&
    !typeForProductArray.length

  const filterParams = {
    brands: values.brands?.flatMap(value => value?.id)?.join(','),
    productType: values.productTypes?.flatMap(value => value?.id)?.join(','),
    productTags: values.productTags?.flatMap(value => value?.id)?.join(','),
    includeIngredients: values.includeIngredients
      ?.flatMap(value => value?.id)
      ?.join(','),
    excludeIngredients: values.excludeIngredients
      ?.flatMap(value => value?.id)
      ?.join(','),
  }

  // Debounce the filter parameters
  const debouncedFilterParams = useDebounce(filterParams, 1000)

  // API call
  const { data: allItemsBySearch, isLoading: isAllItemsBySearchLoading } =
    useGetAllItemsBySearch(
      {
        search,
        sizePerType: 5,
        packagingType,
        display: true,
        ...debouncedFilterParams,
      },
      {
        query: {
          enabled: !!search,
        },
      }
    )

  // Effects
  const onSearchItemClick = (item: SearchDTO) => {
    if (item.searchType === SearchType.PRODUCT) {
      setSearch(item.itemName)
      setFieldValue('searchItemId', item.itemId)
      setFieldValue('searchType', item.searchType)
    } else {
      setSearch('')
    }
  }

  const onClickItem = (item: SearchDTO, type: SearchType) => {
    const fieldMapping = {
      [SearchType.BRAND]: 'brands',
      [SearchType.INGREDIENT]: 'includeIngredients',
      [SearchType.TAG_FOR_PRODUCT]: 'productTags',
      [SearchType.TYPES_FOR_PRODUCT]: 'productTypes',
    }

    const field = fieldMapping[type]
    if (!field) return

    let newValues = [...values[field]]
    const itemIndex = newValues.findIndex(val => val.id === item.itemId)

    if (itemIndex > -1) {
      newValues.splice(itemIndex, 1)
    } else {
      newValues.push({ id: item.itemId, name: item.itemName })
    }

    setFieldValue(field, newValues)
  }

  useEffect(() => {
    if (allItemsBySearch) {
      setBrandArray(
        allItemsBySearch.filter(item => item.searchType === SearchType.BRAND)
      )
      setIngredientArray(
        allItemsBySearch.filter(
          item => item.searchType === SearchType.INGREDIENT
        )
      )
      setProductArray(
        allItemsBySearch.filter(item => item.searchType === SearchType.PRODUCT)
      )
      setTagForProductArray(
        allItemsBySearch.filter(
          item => item.searchType === SearchType.TAG_FOR_PRODUCT
        )
      )
      setTypeForProductArray(
        allItemsBySearch.filter(
          item => item.searchType === SearchType.TYPES_FOR_PRODUCT
        )
      )
    }
  }, [allItemsBySearch])

  return {
    brandArray,
    ingredientArray,
    productArray,
    tagForProductArray,
    typeForProductArray,
    isAllItemsBySearchLoading,
    onClickItem,
    search,
    shouldHidePopup,
  }
}
