import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../../utils/constants'

export const SuggestionContainer = styled.div`
  display: flex;
  position: absolute;
  background: white;
  top: calc(100% + 0.625rem);
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.9375rem 2.8125rem 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  min-width: 19.0625rem;
  max-width: 200%;
  width: auto;
  max-height: 31.25rem;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 15;
  ${MediaQuery.M_AND_DOWN} {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
`

export const NewWrapper = styled.div``

export const NoFilterFound = styled.h3`
  font-size: 0.875rem;
  color: #080038;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.25rem;
`

export const SubTitleNotFound = styled.div`
  font-size: 0.75rem;
  color: #080038;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const Title = styled.h3`
  font-size: 0.875rem;
  color: #080038;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 1.25rem;
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
`
export const CloseSuggestion = styled.p`
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.625rem;
  color: #080038;
  font-family: 'Inter-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #080038;
  margin-top: 0.125rem;
`

export const ProductImgContainer = styled.div`
  margin-right: 0.625rem;
`

export const ProductBtn = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${Color.DEEP_SEA};
  margin-left: 0.625rem;
`

export const WrapperByType = styled.div`
  margin-bottom: 1.25rem;
`
