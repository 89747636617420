import {
  MiniProductPreviewWrapper,
  ProductName,
  MoreDetails,
  Brand,
  CheckboxContainer,
  Checkbox,
  StyledCheckBig,
} from './style'
import { MiniProductPreviewProps } from './props'
import ProductPreviewCardDetailsDialog from '../../../../product/product-preview-card/product-details-dialog'
import { SearchType } from '../../../../../services/api/types'
import { useProductPreviewLogic } from './logic'

const MiniProductPreview = (props: MiniProductPreviewProps) => {
  // Attributes
  const { isDetailModalOpen, setIsDetailModalOpen, product } =
    useProductPreviewLogic(props)

  // Render
  return (
    <MiniProductPreviewWrapper onClick={props.onClick}>
      <CheckboxContainer>
        <Checkbox type='checkbox' checked={props.isChecked} />
        <StyledCheckBig />
      </CheckboxContainer>
      <ProductName>
        {props.item.itemName} {product?.brand?.name && ' - '}
        <Brand>{product?.brand?.name}</Brand>
      </ProductName>
    </MiniProductPreviewWrapper>
  )
}

export default MiniProductPreview
