import styled from 'styled-components/macro'
import CheckBig from '../../../../ui/icon/icons/CheckBig'

export const MiniProductPreviewWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
`

export const ProductName = styled.p`
  margin-left: 0.25rem;
  font-size: 0.75rem;
  flex: 1;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #080038;
`
export const Brand = styled.span`
  font-style: italic;
`

export const MoreDetails = styled.button`
  cursor: pointer;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #65b293;
  text-decoration: underline #65b293;
  border: none;
  background: none;
  margin-left: 2.5rem;
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ValueItem = styled.div`
  cursor: pointer;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const CheckboxContainer = styled.div`
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  border-radius: 0.125rem;
  border: 0.0313rem solid #8f95b2;
`

export const Checkbox = styled.input`
  position: absolute;
  cursor: pointer;
  &[type='checkbox'] {
    appearance: none;
    width: 0.9375rem;
    height: 0.9375rem;
    background-color: #fff;
    border: 0.0625rem solid #00875c;
    border-radius: 0.1875rem;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &:checked + svg {
      display: block;
    }
  }
`

export const StyledCheckBig = styled(CheckBig)`
  display: none;
  position: absolute;
  top: 0rem;
  background-color: #65b293;
  border-radius: 0.0625rem;
  width: 100%;
  height: 100%;
  pointer-events: none;
`
