/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type SearchType =
  | 'BRAND'
  | 'INGREDIENT'
  | 'PRODUCT'
  | 'TYPES_FOR_PRODUCT'
  | 'TAG_FOR_PRODUCT'

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchType = {
  BRAND: 'BRAND' as SearchType,
  INGREDIENT: 'INGREDIENT' as SearchType,
  PRODUCT: 'PRODUCT' as SearchType,
  TYPES_FOR_PRODUCT: 'TYPES_FOR_PRODUCT' as SearchType,
  TAG_FOR_PRODUCT: 'TAG_FOR_PRODUCT' as SearchType,
}
