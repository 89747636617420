import { useEffect, useState } from 'react'
import { MiniProductPreviewProps } from './props'
import { useGetProductByIdOrHandle } from '../../../../../services/api/product/product'
import { ILogicReturnType } from './types'

export const useProductPreviewLogic = ({
  item,
  setIsInnerDialogOpen,
}: MiniProductPreviewProps): ILogicReturnType => {
  // Attributes
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const { data: product } = useGetProductByIdOrHandle(item?.itemId, null, {
    query: { enabled: !!item?.itemId },
  })

  // Effects
  useEffect(() => {
    if (setIsInnerDialogOpen) {
      setIsInnerDialogOpen(isDetailModalOpen)
    }
  }, [isDetailModalOpen, setIsInnerDialogOpen])

  // Return
  return {
    isDetailModalOpen,
    setIsDetailModalOpen,
    product,
  }
}
