import {
  CloseSuggestion,
  NoFilterFound,
  SubTitleNotFound,
  SuggestionContainer,
  Title,
  TitleContainer,
  WrapperByType,
} from './style'
import { SuggestionsProps } from './props'
import { SearchType } from '../../../../services/api/types'
import { Spinner } from '../../../ui'
import MiniProductPreview from './mini-product-preview'
import { useSuggestionsLogic } from './logic'

const Suggestions = (props: SuggestionsProps) => {
  // Logic
  const {
    brandArray,
    ingredientArray,
    tagForProductArray,
    typeForProductArray,
    isAllItemsBySearchLoading,
    onClickItem,
    search,
  } = useSuggestionsLogic(props)

  const areAllArraysEmpty =
    !brandArray.length &&
    !ingredientArray.length &&
    !tagForProductArray.length &&
    !typeForProductArray.length

  function handleCloseSuggestion() {
    props.setIsSuggestionsOpen(false)
  }

  // Render
  return (
    <SuggestionContainer>
      {isAllItemsBySearchLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          {search.length && areAllArraysEmpty ? (
            <>
              <NoFilterFound>Aucun filtre disponible.</NoFilterFound>
              <SubTitleNotFound>
                Vous pouvez tout de même effectuer votre
              </SubTitleNotFound>
              <SubTitleNotFound>
                recheche pour trouver un produit correspondant.
              </SubTitleNotFound>
            </>
          ) : (
            <>
              {!!ingredientArray.length && (
                <>
                  <Title>Filtrer par ingrédients</Title>
                  {ingredientArray.map(item => (
                    <MiniProductPreview
                      key={item.itemId}
                      onClick={() => onClickItem(item, SearchType.INGREDIENT)}
                      item={item}
                      isChecked={props.selectedIngredients.some(
                        ingredient => ingredient.id === item.itemId
                      )}
                    />
                  ))}
                  <CloseSuggestion onClick={() => handleCloseSuggestion()}>
                    Fermer les suggestions
                  </CloseSuggestion>
                </>
              )}
              {!!ingredientArray.length && <WrapperByType />}
              {!!brandArray.length && (
                <>
                  <Title>Filtrer par marques</Title>
                  {brandArray.map(item => (
                    <MiniProductPreview
                      key={item.itemId}
                      onClick={() => onClickItem(item, SearchType.BRAND)}
                      item={item}
                      isChecked={props.selectedBrands.some(
                        brand => brand.id === item.itemId
                      )}
                    />
                  ))}
                  <CloseSuggestion onClick={() => handleCloseSuggestion()}>
                    Fermer les suggestions
                  </CloseSuggestion>
                </>
              )}
              {!!brandArray.length && <WrapperByType />}
              {!!tagForProductArray.length && (
                <>
                  <Title>Filtrer par objectifs de santé</Title>
                  {tagForProductArray.map(item => (
                    <MiniProductPreview
                      key={item.itemId}
                      onClick={() =>
                        onClickItem(item, SearchType.TAG_FOR_PRODUCT)
                      }
                      item={item}
                      isChecked={props.selectedProductTags.some(
                        tag => tag.id === item.itemId
                      )}
                    />
                  ))}
                  <CloseSuggestion onClick={() => handleCloseSuggestion()}>
                    Fermer les suggestions
                  </CloseSuggestion>
                </>
              )}
              {!!tagForProductArray.length && <WrapperByType />}
              {!!typeForProductArray.length && (
                <>
                  <Title>Filtrer par types de produit</Title>
                  {typeForProductArray.map(item => (
                    <MiniProductPreview
                      key={item.itemId}
                      onClick={() =>
                        onClickItem(item, SearchType.TYPES_FOR_PRODUCT)
                      }
                      item={item}
                      isChecked={props.selectedProductTypes.some(
                        type => type.id === item.itemId
                      )}
                    />
                  ))}
                  <CloseSuggestion onClick={() => handleCloseSuggestion()}>
                    Fermer les suggestions
                  </CloseSuggestion>
                </>
              )}
            </>
          )}
        </>
      )}
    </SuggestionContainer>
  )
}

export default Suggestions
